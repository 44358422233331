
.service-description-box {
  width: 100%;
  padding: 90px 0;
}
.service-description {
  width: 720px;
  margin: 0 auto;
  padding: 40px;
  box-shadow: 0 0 16px 0 rgba(43, 43, 43, 0.09);
  p {
    font-size: 16px;
    margin-bottom: 0;
  }
}
.header-title {
  margin: 0 0 24px;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  text-align: center;
}
.btn-box {
  text-align: center;
  margin-top: 30px;
}
.dialog-content {
  color: #333;
}
